// Your dragon theme colors
$dragon-dark: #0A0A0A;
$dragon-purple: #5C2D91;
$light-dragon-pink: #E03F8E;
$dragon-pink: #DA257D;
$dragon-blue: #3B5FCE;
$dragon-cyan: #31D8F2;

// Overriding Bulma's default color scheme
$primary: $dragon-purple;
$link: $dragon-pink;
$info: $dragon-dark;
$success: $dragon-pink;
$warning: $dragon-cyan;
$danger: $dragon-pink;
$dark: $dragon-dark;
$text: $dragon-dark;

// Overriding Bulma's default navbar colors
$navbar-background-color: $dragon-dark;
$navbar-item-color: white;
$navbar-item-hover-color: $dragon-pink;

// Overriding Bulma's default card colors
$body-background-color: $dragon-dark;
$body-color: white;
$border-color: $dragon-pink;

$card-background-color: $dragon-dark;
$card-color: white;
$card-header-background-color: $dragon-dark;
$card-header-color: white;
$card-footer-background-color: $dragon-pink;
$card-shadow: $dragon-pink;

$link: white;

$box-background-color: $dragon-dark;
$box-color: white;
$title-color: $dragon-pink;
$footer-color: white;

@import "/node_modules/bulma/bulma.sass";
@import "/node_modules/animate.css/animate.min.css";

body {
  background-color: $light;
}

nav {
  .navbar-brand {
    align-items: center;
  }

  & h1.title {
    & > span {
      color: $light-dragon-pink;
    }
  }

}

.logo {
  padding: 0.5rem 0 0.5rem 0.5rem;

  img {
    height: 3rem;
    max-height: none;
  }
}

.styled-link {
  color: $light-dragon-pink !important;
}

.content .my-links ul {
  margin: 0;
}

.my-links li {
  display: inline-block;
}

nav h1.title {
  @include mobile {
    font-size: 1.4rem;
    line-height: 1.1;
    display: block !important;

    span {
      display: block;
    }
  }
}

iframe {
  width: 100%;
  display: flex;
  height: 100%;
}

.section--full-height {
  min-height: calc(100vh - 3.25rem);
  display: flex;
}

.full-height {
  min-height: 100%;
}

.display-flex {
  display: flex;
}

.content--flex {
  display: flex;
  flex-grow: inherit;
}

.article--flex {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.notification--box {
  padding: 1.25rem 1.5rem 1.25rem 1.5rem;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-header {
  background: linear-gradient(-40deg, $dragon-pink, $light-dragon-pink 45%, $dragon-pink 45%) $dragon-pink;
  animation: backgroundShift 20s infinite linear;
}

.card-footer {
  font-weight: $weight-bold;
  background: linear-gradient(-40deg, $dragon-purple, lighten($dragon-purple, 15%) 50%, $dragon-purple 45%) $dragon-purple;
  background-size: 150% 100%;
  background-position: 0 center;
  transition: background-position 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);

  &:hover {
    background-position: 100% center;
  }
}


.card footer a {
  color: $card-color;
  text-decoration: none;
}

.card-content {
  flex-grow: 1;
}

@keyframes backgroundShift {
  0% {
    background-size: 100% 100%;
    background-position: 0% 0%;
  }
  50% {
    background-size: 150% 150%;
    background-position: 50% 50%;
  }
  100% {
    background-size: 100% 100%;
    background-position: 0% 0%;
  }
}

#flair a {
  width: 100%;
  display: flex;
  background-color: #EEE;
  height: 58px;
}

@include until($desktop) {
  .upto-tablet-col-2 {
    height: 100vh;
  }

  .content--flex {
    margin-left: -24px;
    margin-right: -40px;
    margin-bottom: -20px;
  }
}

section.hero {
  padding-top: 27px;
  background: linear-gradient(-40deg, lighten($dragon-purple, 8%), $dragon-pink 45%, lighten($dragon-purple, 8%) 45%) lighten($dragon-purple, 8%);
}

section.hero .subtitle {
  font-size: 1.2rem;
  @include tablet {
    font-size: 1.5rem;
  }
  color: $dragon-dark;
}

section.hero .title {
  font-size: 2.5rem;
  @include tablet {
    font-size: 3.5rem;
  }
  color: $dragon-dark;
}

.hero .container .section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero .column.is-justify-content-flex-end {
  display: flex;
}

@keyframes hoverEffect {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.hovering-element {
  animation: hoverEffect 4s ease-in-out infinite;
}

.cursor {
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

#intro,
#flair,
#links,
.box {
  border-left: 5px solid $dragon-pink;
}

.footer {
  background: linear-gradient(-40deg, lighten($dragon-purple, 10%), lighten($dragon-purple, 15%) 45%, lighten($dragon-purple, 10%) 45%) lighten($dragon-purple, 10%);
  color: $footer-color;
  padding: $footer-padding;

  .level {
    justify-content: center;
  }

  .level-item {
    margin: 0 1rem;
  }

  a {
    color: $footer-color;

    &:hover {
      color: lighten($footer-color, 10%);
    }
  }

  .icon {
    margin-right: 0.5rem;
  }
}
